import React from 'react';
import { Layout, Row, Col, Card, Statistic, Table } from 'antd';

const { Header, Content } = Layout;

const usedCouponsData = [
  { key: '1', couponCode: 'DISCOUNT10', usedBy: 'User 1', usedAt: '2025-01-01' },
  { key: '2', couponCode: 'DISCOUNT20', usedBy: 'User 2', usedAt: '2025-01-10' },
];

const createdCouponsData = [
  { key: '1', couponCode: 'DISCOUNT10', createdAt: '2025-01-01', status: 'Active' },
  { key: '2', couponCode: 'DISCOUNT20', createdAt: '2025-01-05', status: 'Expired' },
];

const campaignsData = [
  { key: '1', campaignName: 'Winter Sale', startDate: '2025-01-01', endDate: '2025-01-31' },
  { key: '2', campaignName: 'Spring Promo', startDate: '2025-02-01', endDate: '2025-02-28' },
];

const usedCouponsColumns = [
  { title: 'Coupon Code', dataIndex: 'couponCode' },
  { title: 'Used By', dataIndex: 'usedBy' },
  { title: 'Used At', dataIndex: 'usedAt' },
];

const createdCouponsColumns = [
  { title: 'Coupon Code', dataIndex: 'couponCode' },
  { title: 'Created At', dataIndex: 'createdAt' },
  { title: 'Status', dataIndex: 'status' },
];

const campaignsColumns = [
  { title: 'Campaign Name', dataIndex: 'campaignName' },
  { title: 'Start Date', dataIndex: 'startDate' },
  { title: 'End Date', dataIndex: 'endDate' },
];

const Dashboard = () => {
  return (
    <Layout>
      <Header style={{ background: '#fff', padding: 0, textAlign: 'center', fontSize: '24px' }}>
        Dashboard
      </Header>
      <Content style={{ padding: '20px' }}>
        <Row gutter={16}>
          <Col span={8}>
            <Card title="Used Coupons">
              <Table dataSource={usedCouponsData} columns={usedCouponsColumns} pagination={false} />
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Created Coupons">
              <Table dataSource={createdCouponsData} columns={createdCouponsColumns} pagination={false} />
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Campaigns">
              <Table dataSource={campaignsData} columns={campaignsColumns} pagination={false} />
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Dashboard;
