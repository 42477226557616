import React, { useState, useEffect, useRef } from 'react';
import {
  App, Alert, Switch, Card, Space, Row, Col, Form, Input, Tabs, Skeleton, Button, Checkbox, ColorPicker, Typography
} from 'antd';
import { ArrowLeftOutlined, HighlightOutlined, CheckOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { axiosClient } from '../../apiClient';
import { useNavigate, useParams } from 'react-router-dom';
import SellerHideProducts from '../SellerHideProducts'
import SellerMinimumOrder from '../SellerMinimumOrder'

const { Title, Link } = Typography;

export default function SellerOptions() {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const themeConfig = useSelector((state) => state.themeConfig);
  const dataBase = useSelector((state) => state.indexedDB.dataBase);

  async function getSingleData(dBStoreName, idx) {
    const value = await dataBase.get(dBStoreName, idx);
    return value;
  }

  useEffect(() => {
    setLoading(true);
    axiosClient
      .get('/config/default-values', { withCredentials: true })
      .then((response) => {
        setData({
          ...response.data,
          freight_weight_default: String(response.data.freight_weight_default),
          freight_size_default: String(response.data.freight_size_default),
        });
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setData({
      data,
      ...themeConfig
    });
  }, [themeConfig]);

  const normalizeColor = (color) =>
    typeof color === 'string' ? color : color.toHexString();

  const onFinish = async (values) => {
    const allValues = {
      ...values,
      isDark: themeConfig.isDark,
      colorPrimary: values.colorPrimary ? normalizeColor(values.colorPrimary) : null,
      colorTextBase: values.colorTextBase ? normalizeColor(values.colorTextBase) : null,
      colorBgBase: values.colorBgBase ? normalizeColor(values.colorBgBase) : null,
      colorInfo: values.colorInfo ? normalizeColor(values.colorInfo) : null,
    };

    dataBase && getSingleData('config', 5).then((data) => {
      const updatedKeys = ["colorBgBase", "colorPrimary", "colorTextBase", "colorInfo", "isDark"];
      updatedKeys.forEach((key) => {
        if (allValues.hasOwnProperty(key)) {
          data.configuration[key] = allValues[key];
        }
      });
      dataBase.put('config', data);
    });

    setLoading(true);
    axiosClient
      .post('/config/default-values', allValues, { withCredentials: true })
      .then((response) => {
        setData(response.data);
        setLoading(false);
      });
  };

  const handleColorChange = (color, type) => {
    dispatch({ type: 'SET_THEME', [type]: color });
  };


  const sendValues = (values) => {
    axiosClient
      .post('/config/default-values', values, { withCredentials: true })
      .then((response) => {
        message.success('Configuração atualizada com sucesso')
      })
  }

  const SwitchWithLabel = (props) => {
    return (
      <Space>
        <Switch {...props} onChange={props.onChange} />
        {props.label}
      </Space>
    );
  };

  const EditableButton = (props) => {
    const [editValue, setEditValue] = useState(false);
    const [newValue, setNewValue] = useState(null);
    const inputRef = useRef();
    const formValue = Form.useWatch(props.name, props.form);

    useEffect(() => {
      if (editValue) {
        setNewValue(formValue)
        inputRef.current?.focus();
      }
    }, [editValue]);


    return (
      <>
        {editValue ? (
          <Space.Compact>
            <Input
              {...props}
              ref={inputRef}
              value={newValue}
              suffix={props.suffix}
              onChange={(e) => {
                if (props.numType === 'float') {
                  const val = Math.max(0, Math.min(parseInt(e.target.value.replace(/\D/g, '')) / 100, 1000));
                  setNewValue(val.toFixed(2));
                } else {
                  const val = Math.max(0, Math.min(parseInt(e.target.value.replace(/\D/g, '')), 1000)) || 0
                  setNewValue(val);
                }

              }}
              onBlur={(e) => {
                setTimeout(() => {
                  setEditValue(false);
                }, 500);
              }}
              placeholder={props.placeholder}
              onPressEnter={() => {
                sendValues({ [props.name]: newValue });
                props.form.setFields([
                  {
                    name: props.name,
                    value: newValue,
                  },
                ])
                setEditValue(false)
              }}
            />
            <Button onClick={() => {
              sendValues({ [props.name]: newValue });
              props.form.setFields([
                {
                  name: props.name,
                  value: newValue,
                },
              ])
              setEditValue(false)
            }} icon={<CheckOutlined />} type="primary" />
          </Space.Compact>
        ) : (
          <Button onClick={() => setEditValue(true)} variant="filled">
            {formValue} {props.suffix} <Link><HighlightOutlined /></Link>
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <Card
          styles={{ header: { paddingLeft: 8, paddingRight: 8 } }}
          title={
            <Space size={8} style={{ height: '100%' }}>
              <Button onClick={() => navigate('/admin')} size='large' type="text" icon={<ArrowLeftOutlined />} />
              <Title level={4} style={{ margin: 0 }}>Mais Opções</Title>
            </Space>
          }
        >

          <Tabs size="large" type="card" centered>
            <Tabs.TabPane key="customization" tab="Customização">
              <Form
                form={form}
                scrollToFirstError
                onFinish={onFinish}
                initialValues={data}
              >
                <Row gutter={[16, 16]} justify="center" align="middle">
                  {['colorPrimary', 'colorTextBase', 'colorInfo'].map((field, index) => (
                    <Col xs={24} sm={4} key={index}>
                      <Form.Item
                        name={field}
                        label={field === 'colorPrimary' ? 'Cor Principal' : `Cor ${field.split('color')[1]}`}
                      >
                        <ColorPicker
                          allowClear
                          disabledAlpha
                          onClear={() => handleColorChange(null, field)}
                          onChangeComplete={(color) => handleColorChange(color.toHexString(), field)}
                        />
                      </Form.Item>
                    </Col>
                  ))}
                  <Col xs={24} sm={4}>
                    <Button onClick={() => {
                      form.setFieldsValue({ colorTextBase: themeConfig.isDark ? '#000000' : '#FFFFFF' });
                      dispatch({ type: 'SET_THEME', isDark: !themeConfig.isDark, colorTextBase: themeConfig.isDark ? '#000000' : '#FFFFFF' })
                    }}>
                      {themeConfig.isDark ? 'Modo Claro' : 'Modo Escuro'}
                    </Button>
                  </Col>
                </Row>
                <Form.Item>
                  <Row justify="center" gutter={[30, 12]}>
                    <Col xs={18} sm={8}>
                      <Button block type="primary" loading={loading} htmlType="submit">
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Tabs.TabPane>

            <Tabs.TabPane key="stock" tab="Estoque">
              <Form
                form={form}
                scrollToFirstError
                onFinish={onFinish}
                initialValues={data}
              >
                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <Form.Item name="hide_out_of_stock" valuePropName="checked">
                      <SwitchWithLabel label='Ocultar produtos sem estoque para os clientes' onChange={(checked) => sendValues({ hide_out_of_stock: checked })} />
                    </Form.Item>
                    <Form.Item name="order_out_of_stock" valuePropName="checked">
                      <SwitchWithLabel label='Fechar pedidos com estoque negativo' onChange={(checked) => sendValues({ order_out_of_stock: checked })} />

                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Tabs.TabPane>

            <Tabs.TabPane key="freight" tab="Frete">
              <Form
                form={form}
                scrollToFirstError
                onFinish={onFinish}
                initialValues={data}
              >
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Alert
                    message="Defina o peso e o tamanho padrão para calcular o frete de produtos que não possuem esses dados cadastrados."
                    type="info"
                    showIcon
                  />
                  <Row gutter={16}>
                    <Col xs={24} sm={12}>
                      <Form.Item name="freight_weight_default" label="Peso Padrão">
                        <EditableButton numType="float" name="freight_weight_default" suffix='kg' form={form} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item name="freight_size_default" label="Tamanho Padrão">
                        <EditableButton name="freight_size_default" suffix='cm' form={form} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Space>
              </Form>
            </Tabs.TabPane>

            <Tabs.TabPane key="min-order" tab="Pedido Mínimo">
              <SellerMinimumOrder form={form} />
            </Tabs.TabPane>

            <Tabs.TabPane key="hide-products" tab="Ocultar Produtos">
              <SellerHideProducts />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      )}
    </>
  );
}
