import React, { useState, useEffect, useLayoutEffect } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Table, Button, Skeleton, Card, Alert, Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { axiosClient } from '../../apiClient';
import { mascaraCpf } from '../../functions/validation';

const { Link } = Typography;

export default function SellerListUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [listUsers, setListUsers] = useState([]);
  const [repOptions, setRepOptions] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosClient
      .get('/auth/list/seller-users-credit', { withCredentials: true })
      .then((response) => {
        setRepOptions(response.data.products);
        setListUsers(
          response.data.seller_users_list.map((obj, index) => ({ ...obj, key: index })),
        );
        setLoading(false);
      });
  }, []);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const [width, height] = useWindowSize();

  const smallColumns = [
    {
      title: 'Descrição',
      render: (record) => (
        <>
          <Link>{record.nome}</Link>
          {record.cpf && (
            <>
              <br />
              {' '}
              {mascaraCpf(record.cpf)}
            </>
          )}
          {record.email && (
            <>
              <br />
              {' '}
              {record.email}
            </>
          )}
        </>
      ),
      ellipsis: true,
    },
  ];

  const bigColumns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      width: '25%',
      render: (text, record) => (
        <Link>{record.nome}</Link>
      ),
      ellipsis: true,
    },
    {
      title: 'CPF/CNPJ',
      dataIndex: 'documento',
      render: (text, record) => <>{record.cpf && mascaraCpf(record.cpf)}</>,
    },
    {
      title: 'Email',
      render: (text, record) => <>{record.email && record.email}</>,
    },
  ];

  function checkColumns() {
    if (width < 1100) {
      return smallColumns;
    }
    return bigColumns;
  }

  const columns = checkColumns();

  return (
    <>
      {loading && <Skeleton active />}
      {!loading && (
        (!loading && Object.keys(repOptions).length === 0)
          ? (
            <Card>
              <Alert
                message="Você não possui representantes no seu plano. Contate a administração caso queira contratar esse serviço."
                type="info"
                showIcon
              />
            </Card>
          )
          : Object.keys(repOptions).map((opt, i) => (
            <>
              <PageHeader
                key={`pg${i}`}

                title={`${repOptions[opt].name}`}
                subTitle={`${listUsers.filter((i) => i.type.includes(opt)).length} de ${repOptions[opt].quantity}`}
                extra={(
                  <Button
                    disabled={listUsers.filter((i) => i.type.includes(opt)).length >= repOptions[opt].quantity}
                    key={`bt${opt}${i}`}
                    onClick={() => {
                      navigate(`/admin/configuracoes/representantes/cadastro`)
                    }}
                    type="primary"
                  >
                    Adicionar
                  </Button>
                )}
              />
              <Table
                key={`tab${opt}${i}`}
                columns={columns}
                dataSource={listUsers.filter((i) => i.type.includes(opt))}
                style={{ cursor: !loading ? 'pointer' : 'auto' }}
                onRow={(record, rowIndex) => ({
                  onClick: (event) => {
                    navigate(`/admin/configuracoes/representantes/cadastro/${record.id}`);
                  },
                })}
              />
            </>
          ))
      )}
    </>
  );
}
