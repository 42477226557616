import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Select, Spin } from 'antd';
import { debounce } from 'lodash';
import { axiosClient } from '../../apiClient';
import {
  mascaraCnpj,
  mascaraCpf,
} from '../../functions/validation';

export default function SearchCustomerRules(props) {
  const dispatch = useDispatch();
  const { Option } = Select;
  const buyerPagination = useSelector((state) => state.buyerPagination);
  const searchTermValue = useSelector(
    (state) => state.buyerSearchState.searchTerm,
  );
  const buyerList = useSelector((state) => state.buyerList);

  useEffect(() => { dispatch({ type: 'SET_BUYERS', loading: true }); }, []);

  const updateSearch = async (page, perPage, searchTerm, resetPage) => {
    try {
      const results = await axiosClient.post('/auth/search/buyer', {
        search: searchTerm,
        page: resetPage ? 1 : page,
        perPage,
      }, { withCredentials: true });
      dispatch({ type: 'SET_BUYERS', list: results.data.buyers, loading: false });
      dispatch({
        type: 'SET_BUYER_PAGINATION',
        totalPages: results.data.total_pages,
        actualPage: results.data.actual_page,
        pageUpdated: true,
      });
    } catch (error) { }
  };

  const updateSearchHandler = useCallback(debounce(updateSearch, 500), []);

  const handleSearchChangeSelect = async (value) => {
    dispatch({ type: 'SET_BUYER_SEARCH_TERM', searchTerm: value });
  };

  useEffect(() => {
    dispatch({ type: 'SET_BUYERS', loading: true });
    updateSearchHandler(
      buyerPagination.page,
      buyerPagination.perPage,
      searchTermValue,
      true,
    );
  }, [searchTermValue, buyerPagination.perPage]);

  useEffect(() => {
    !buyerPagination.pageUpdated
      && updateSearchHandler(
        buyerPagination.page,
        buyerPagination.perPage,
        searchTermValue,
        false,
      );
  }, [buyerPagination.page]);

  return (
    <Select
      {...props}
      showSearch
      mode="multiple"
      // onChange={props.handleSelectChange}
      autoClearSearchValue={false}
      style={{ width: '100%' }}
      placeholder="Selecione os clientes"
      allowClear
      onSearch={handleSearchChangeSelect}
      loading={buyerList.loading}
      filterOption={false}
      notFoundContent={buyerList.loading ? <Spin size="small" /> : null}
    >
      {buyerList.loading
        ? null
        : buyerList.list.map((option) => (option.tipo_pessoa === 'pf' ? (
          <Option key={option.id} value={option.id}>
            {`${mascaraCpf(option.cpf)} - ${(option.nome_fantasia || option.nome)}`}
          </Option>
        ) : (
          <Option key={option.id} value={option.id}>
            {`${mascaraCnpj(option.cnpj)} - ${option.razao_social}`}
          </Option>
        )))}
    </Select>
  );
}