import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input } from 'antd';
import { debounce } from 'lodash';
import { openDB } from 'idb';
import { axiosClient } from '../../apiClient';

export default function SearchProduct(props) {
  const dispatch = useDispatch();
  const page = useSelector((state) => state.pagination.page);
  const perPage = useSelector((state) => state.pagination.perPage);
  const column = useSelector((state) => state.pagination.column);
  const order = useSelector((state) => state.pagination.order);
  const pageUpdated = useSelector((state) => state.pagination.pageUpdated);
  const searchTermValue = useSelector((state) => state.searchState.searchTerm);
  const searchCategories = useSelector((state) => state.searchState.categories);
  const searchTags = useSelector((state) => state.searchState.tags);
  const searchFilterType = useSelector((state) => state.searchState.filterType);
  const buyerView = useSelector((state) => state.buyerView.buyerView);
  const logged = useSelector((state) => state.authentication.logged);
  const syncStatus = useSelector((state) => state.sync.syncStatus);

  const updateSearch = async (
    page,
    perPage,
    searchTerm,
    column,
    order,
    resetPage,
    buyerView,
    searchCategories,
    searchTags,
    searchFilterType,
    syncStatus,
  ) => {
    dispatch({ type: 'SET_PRODUCTS', list: [], loading: true });
    if ((logged === 'buyer' || logged === 'seller' || logged === 'sellerUser') && (props.dontUseBuyerView || buyerView)) {
      const db = await openDB('nupedido', 1);
      const dbConfig = await db.get('config', 1);

      if (dbConfig.synced || syncStatus === 'synced') {
        const tx = await db.transaction('products', 'readonly');
        let cursor = await tx.store.openCursor();
        const results = [];
        const newSearchTerm = searchTerm.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

        while (cursor) {
          const { key, value } = cursor;
          const normalizedGtin = value?.gtin?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '';
          const normalizedProductName = value?.product_name?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '';
          const normalizedProductSku = value?.product_sku?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '';

          if (
            value &&
            (normalizedGtin.includes(newSearchTerm) ||
              normalizedProductName.includes(newSearchTerm) ||
              normalizedProductSku.includes(newSearchTerm))
          ) {
            results.push(value);
          }

          cursor = await cursor.continue();
        }

        results.sort((a, b) => (a.product_name || '').localeCompare(b.product_name || ''));
        const totalPages = Math.ceil(results.length / perPage);
        const startIndex = (page - 1) * perPage;
        const paginatedResults = results.slice(startIndex, startIndex + perPage);

        dispatch({ type: 'SET_PRODUCTS', list: paginatedResults, loading: false });
        dispatch({
          type: 'SET_PAGINATION',
          totalPages,
          page: page,
          pageUpdated: true,
        });
      } else {
        const results = await axiosClient.post('/products/search', {
          search: searchTerm,
          page: resetPage ? 1 : page,
          perPage,
          column,
          order,
          categories: searchCategories,
          tags: searchTags,
          filter: searchFilterType,
          buyerViewId: props.dontUseBuyerView ? null : buyerView.id,
        }, { withCredentials: true });

        const filteredParents = results.data.products.filter((product) => product.variation_type !== 'P')
        dispatch({ type: 'SET_PRODUCTS', list: filteredParents, loading: false });

        dispatch({
          type: 'SET_PAGINATION',
          totalPages: results.data.total_pages,
          page: results.data.actual_page,
          pageUpdated: true,
          column: results.data.column,
          order: results.data.order,
        });
      }

      window.scrollTo(0, 0);
    }

  };

  const updateSearchHandler = useCallback(debounce(updateSearch, 500), []);

  useEffect(() => {
    updateSearchHandler(
      page,
      perPage,
      searchTermValue,
      column,
      order,
      true,
      props.dontUseBuyerView ? false : buyerView,
      searchCategories,
      searchTags,
      searchFilterType,
      props.dontUseOffline ? false : syncStatus,
    );
  }, [searchTermValue, searchCategories, searchTags, searchFilterType, buyerView]);

  useEffect(() => {
    !pageUpdated && updateSearchHandler(
      page,
      perPage,
      searchTermValue,
      column,
      order,
      false,
      props.dontUseBuyerView ? false : buyerView,
      searchCategories,
      searchTags,
      searchFilterType,
      props.dontUseOffline ? false : syncStatus,
    );

  }, [pageUpdated]);

  return (
    <Input
      defaultValue={searchTermValue}
      allowClear
      size="large"
      type="text"
      placeholder="Nome, código ou código de barras..."
      onChange={(e) => dispatch({ type: 'SEARCH_TERM', searchTerm: e.target.value })}
      onFocus={() => dispatch({ type: 'SET_SEARCH_FOCUS', onFocus: true })}
      onBlur={() => dispatch({ type: 'SET_SEARCH_FOCUS', onFocus: false })}
    />
  );
}
