import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import {
  Input, Card, Descriptions, Table, Button, Image, Row, Col, Collapse, Grid, Space, Skeleton, Empty, Typography, Modal, Pagination, Popover, theme
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { PlusOutlined, MinusOutlined, DownOutlined, UpOutlined, DeleteOutlined, FireTwoTone } from '@ant-design/icons';
import QtyChangerRow from '../QtyChangerRow';
import {
  changePageQty,
  clearPageQty,
  addToCart,
  removeFromCart,
} from '../../actions/cart';
import { applyBulkDiscount } from '../../functions/applyBulkDiscount';
import { gold } from '@ant-design/colors';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const { Link, Title, Text } = Typography;
const { useToken } = theme;
const { useBreakpoint } = Grid;


const renderProductPrice = (text, product, index, qtyInCart = null) => {
  const priceBulk = product?.product_price_bulk;

  const productQtyInCart = qtyInCart ? qtyInCart : product.qtyInCart

  // Verifica se o produto tem preço de bulk e calcula o preço aplicável
  const bulkPrice = priceBulk
    ? Object.entries(priceBulk).reduce((acc, [minQty, price], index, entries) => {
      const nextMinQty = entries[index + 1]?.[0];
      return productQtyInCart >= minQty && (!nextMinQty || productQtyInCart < nextMinQty) ? price : acc;
    }, null)
    : null;

  const finalPrice = Math.min(
    ...[bulkPrice, product.product_price_sale, product.product_price].filter(price => price != null)
  );
  const priceBulkApplied = bulkPrice !== null;
  const discountApplied = product.product_price_sale !== null;

  const tipColumns = [
    { title: 'Quantidade', dataIndex: 'quantity', key: 'quantity' },
    { title: 'Preço Unitário', dataIndex: 'value', key: 'value', render: value => `R$ ${value.toFixed(2)}` },
  ];


  return (
    <Space align="center" direction="vertical" style={{ gap: '0' }}>
      {(priceBulkApplied || discountApplied) && (
        <Text delete type="secondary" style={{ marginBottom: 0 }}>
          {`R$ ${parseFloat(product.product_price).toFixed(2)}`}
        </Text>
      )}
      {product?.product_price_bulk ? <Popover
        content={
          <Table
            dataSource={Object.entries(priceBulk).map(([minQty, value], index, entries) => ({
              quantity: entries[index + 1] ? `${minQty} a ${entries[index + 1][0] - 1}` : `${minQty}+`,
              value,
            }))}
            columns={tipColumns}
            pagination={false}
            size="small"
          />
        }
        trigger="click"
        placement="topLeft"
      >
        <Space align="center" direction="horizontal" style={{ gap: '0' }}>
          <Button
            type="link"
            icon={<FireTwoTone twoToneColor={gold[5]} />}
            style={{ marginRight: 0 }}
          />
          <Title level={5} strong style={{
            margin: 0,
            cursor: 'pointer'
          }}>
            {`R$ ${parseFloat(finalPrice).toFixed(2)}`}
          </Title>
        </Space>
      </Popover> :
        <Title level={5} strong style={{ margin: 0 }}>
          {`R$ ${parseFloat(finalPrice).toFixed(2)}`}
        </Title>
      }
    </Space>
  );
};


const ProductCard = React.memo(({ product, qtyInCart, updateFeaturedProduct, orderOutOfStock }) => {
  const dispatch = useDispatch();
  const screens = useBreakpoint();

  if (orderOutOfStock === undefined) {
    return null
  }

  const imgSrc = product.product_image?.[0]?.url || '/fallback.png'

  const stock = orderOutOfStock ? 10000 : product.product_stock;
  const [qtyInPage, setQtyInPage] = useState(0);

  return (
    <Col xs={24} sm={12} md={8} lg={6}>
      <Card
        style={{
          marginBottom: 16,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            textAlign: 'center',
            marginBottom: 16,
            height: 200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            preview={product.product_image?.[0] ? { visible: false } : false}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              maxHeight: '200px',
            }}
            src={imgSrc}
            fallback="/fallback.png"
            onClick={() => updateFeaturedProduct(product)}
          />
        </div>
        <div
          style={{
            marginTop: 6,
            height: 48,
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Link onClick={() => updateFeaturedProduct(product)}>
            {product.product_name}
          </Link>
        </div>
        <div>
          <Row justify="space-between" align="middle" style={{ height: 38, marginBottom: 8 }}>
            <Col>
              {parseInt(product.product_stock) > 0
                ? `${product.product_stock} em estoque`
                : 'Sem estoque'}
            </Col>
            <Col>{renderProductPrice(null, product, null, qtyInCart)}</Col>
          </Row>
          <Row
            justify="space-between"
            align="middle"
            style={{ marginTop: 16, width: '100%' }}
          >
            <Col flex="auto">
              <Space.Compact size={screens.md ? 'middle' : 'large'}>
                <Button
                  onClick={() => {
                    setQtyInPage(qtyInPage - 1)
                  }}
                  disabled={stock <= 0 || qtyInPage <= 0}
                  icon={<MinusOutlined />}
                />
                <Input
                  onPressEnter={() => {
                    if (qtyInPage) {
                      dispatch(addToCart(product, qtyInPage, qtyInCart, qtyInPage));
                      dispatch({ type: 'SET_CART', updated: true, returned: false });
                      setQtyInPage(0)
                    }
                  }}
                  pattern="\d*"
                  onChange={(e) => {
                    setQtyInPage(parseInt(e.target.value.replace(/\D/g, '') | 0))
                  }}
                  style={{
                    width: 50,
                    textAlign: 'center',
                    borderRadius: 0,
                  }}
                  value={qtyInPage}
                  disabled={stock <= 0 || stock - qtyInCart <= 0}
                />
                <Button
                  onClick={() => {
                    setQtyInPage(qtyInPage + 1)
                  }}
                  disabled={stock - qtyInCart <= 0 || stock - qtyInCart - qtyInPage <= 0}
                  icon={<PlusOutlined />}
                />
              </Space.Compact>
            </Col>
            <Col>
              <Button
                type="primary"
                size={screens.md ? 'middle' : 'large'}
                onClick={() => {
                  dispatch(addToCart(product, qtyInPage, qtyInCart, qtyInPage));
                  dispatch({ type: 'SET_CART', updated: true, returned: false });
                  setQtyInPage(0)
                }}
              >
                Adicionar
              </Button>
            </Col>
          </Row>
          {qtyInCart > 0 && (
            <div style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              margin: 8,
            }}>
              <Space size={2}>
                <Text>{`${qtyInCart} no Carrinho`}</Text>
                <Button size='small' onClick={() => {
                  dispatch(removeFromCart(product));
                  dispatch({ type: 'CART_UPDATED', updated: true });
                  dispatch({ type: 'CART_RETURNED', returned: false });
                }} icon={<DeleteOutlined />} type="link" />
              </Space>
            </div>
          )}
        </div>
      </Card>
    </Col>
  );
})

const BuyerListProduct = React.memo(({ viewType }) => {
  const dispatch = useDispatch();
  const { token } = useToken();
  const screens = useBreakpoint();

  const [visible, setVisible] = useState(false);
  const [featuredProduct, setFeaturedProduct] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [updatedProductList, setUpdatedProductList] = useState([]);

  const productList = useSelector((state) => state.productList);
  const totalPages = useSelector((state) => state.pagination.totalPages);
  const page = useSelector((state) => state.pagination.page);
  const perPage = useSelector((state) => state.pagination.perPage);
  const cartProducts = useSelector((state) => state.cart.products);
  const pageQty = useSelector((state) => state.pageQty);
  const [rowOnFocus, setRowOnFocus] = useState(0);
  const inputRef = useRef(null);
  const onlineStatus = useSelector((state) => state.sync.onlineStatus);
  const syncStatus = useSelector((state) => state.sync.syncStatus);
  const searchOnFocus = useSelector((state) => state.searchState.onFocus);
  const progressiveDiscountRules = useSelector((state) => state.rules.progressiveDiscountRules);
  const sellerData = useSelector((state) => state.sellerData);

  const [productLoading, setProductLoading] = useState(null);
  const [activeTabKey2, setActiveTabKey2] = useState("description");


  const updateFeaturedProduct = useCallback((product) => {
    setFeaturedProduct(product);
  }, []);

  useEffect(() => setActiveTabKey2(screens.md ? "description" : "image"), [screens.md])


  function isElementVisible(el) {
    const rect = el.getBoundingClientRect();
    const vWidth = window.innerWidth || document.documentElement.clientWidth;
    const vHeight = window.innerHeight || document.documentElement.clientHeight;
    if (
      rect.right < 0
      || rect.bottom < 0
      || rect.left > vWidth
      || rect.top > vHeight
    ) return false;
  }

  function handleFocus() {
    const { input } = inputRef.current;
    input.focus({ preventScroll: isElementVisible(input) });
    input.selectionStart = input.selectionEnd = input.value.length;
  }

  useEffect(() => {
    productLoading === false && setRowOnFocus(0);
  }, [productLoading]);

  function ImageComp(props) {
    let imgSrc = null;

    if (onlineStatus) {
      if (props.product.product_image && props.product.product_image[0]) {
        imgSrc = props.product.product_image[0].thumbnail;
      }
    } else if (onlineStatus === false && syncStatus === 'synced') {
      imgSrc = localStorage.getItem('products' + `_${props.product.id}`);
    }

    return (
      <div style={{
        height: 60,
        width: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 2
      }}>
        <Image
          preview={
            props.product.product_image && props.product.product_image[0]
              ? { visible: false }
              : false
          }
          style={{ maxWidth: 60, maxHeight: 60, objectFit: 'contain' }}
          src={imgSrc || '/fallback.png'}
          onClick={() => { setFeaturedProduct(props.product) }}
        />
      </div>
    );
  }


  const smallColumns = [
    {
      title: 'Produtos',
      render: (text, product, index) => {
        const qtyInPage = pageQty[product.id] || 0;
        return (
          <>
            <Link style={{ marginTop: 4 }} onClick={() => setFeaturedProduct(product)}
            >{product.product_name}</Link>
            <Row justify="space-between" align="middle">
              <Col>
                <>{`Cod.: ${product.product_sku}`}</>
              </Col>
              <Col>
                <>
                  {parseInt(product.product_stock) > 0
                    ? `${product.product_stock} em estoque`
                    : 'Sem estoque'}
                </>
              </Col>
            </Row>
            <Row justify="space-between" align="middle">
              <Col>
                <ImageComp product={product} />
              </Col>
              <Col>
                {renderProductPrice(text, product)}
              </Col>
            </Row>
            <Row justify="space-between" align="middle" style={{ marginBottom: 18 }}>
              <Col>
                <QtyChangerRow
                  changePageQty={changePageQty}
                  updateCart={updateCart}
                  product={product}
                  qtyInCart={product.qtyInCart}
                  rowOnFocus={rowOnFocus}
                  setRowOnFocus={setRowOnFocus}
                  myIndex={index}
                  inputRef={inputRef}
                  handleFocus={handleFocus}
                  searchOnFocus={searchOnFocus}
                  orderOutOfStock={sellerData.orderOutOfStock}
                />
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    updateCart(product, qtyInPage, product.qtyInCart);
                  }}
                >
                  Adicionar
                </Button>
              </Col>
              {product.qtyInCart > 0 && (<div style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                marginBottom: 4,
              }}>
                <Space size={2}>
                  <Text>{`${product.qtyInCart} no Carrinho`}</Text>
                  <Button size='small' onClick={() => {
                    dispatch(removeFromCart(product));
                    dispatch({ type: 'CART_UPDATED', updated: true });
                    dispatch({ type: 'CART_RETURNED', returned: false });
                  }} icon={<DeleteOutlined />} type="link" />
                </Space>
              </div>)}
            </Row>
          </>)
      },
    },
  ];

  const bigColumns = [
    {
      title: 'Imagem',
      dataIndex: 'product_image',
      key: 'product_image',
      align: 'center',
      render: (text, product) => <ImageComp product={product} />,
      shouldCellUpdate: (record, prevRecord) => !_.isEqual(record.id, prevRecord.id)
    },
    {
      title: 'Descrição',
      dataIndex: 'product_name',
      key: 'name',
      style: { cursor: "pointer" },
      render: (text, product) => (
        <Link onClick={() => setFeaturedProduct(product)}>{text}</Link>
      ),
      ellipsis: true,
      width: '30%',
      shouldCellUpdate: (record, prevRecord) => !_.isEqual(record.id, prevRecord.id)
    },
    {
      title: 'Código',
      sorter: () => { },
      dataIndex: 'product_sku',
      key: 'preco',
      align: 'center',
      shouldCellUpdate: (record, prevRecord) => !_.isEqual(record.id, prevRecord.id)
    },
    {
      title: 'Estoque',
      dataIndex: 'product_stock',
      render(text) {
        const stock = parseInt(text, 10);
        return stock > 0
          ? { children: text }
          : { props: { style: { backgroundColor: token.colorErrorBgHover } }, children: 'Sem estoque' };
      },
      key: 'stock',
      align: 'center',
      shouldCellUpdate: (record, prevRecord) => !_.isEqual(record.product_stock, prevRecord.product_stock)
    },
    {
      title: 'Preço',
      dataIndex: 'product_price',
      key: 'preco',
      render: renderProductPrice,
      align: 'center',
      shouldCellUpdate: (record, prevRecord) => !_.isEqual(record.qtyInCart, prevRecord.qtyInCart),
      width: 150,

    },
    {
      title: 'Quantidade',
      render: (text, product, index) => (
        <QtyChangerRow
          changePageQty={changePageQty}
          updateCart={updateCart}
          product={product}
          qtyInCart={product.qtyInCart}
          rowOnFocus={rowOnFocus}
          setRowOnFocus={setRowOnFocus}
          myIndex={index}
          inputRef={inputRef}
          handleFocus={handleFocus}
          searchOnFocus={searchOnFocus}
          orderOutOfStock={sellerData.orderOutOfStock}
        />
      ),
      align: 'center',
    },
    {
      render(text, product) {
        const qtyInPage = pageQty[product.id] || 0;
        return {
          children: (
            <>
              <Button
                type="primary"
                onClick={() => {
                  updateCart(product, qtyInPage, product.qtyInCart);
                }}
              >
                Adicionar
              </Button>
              {
                product.qtyInCart > 0 && (
                  <div style={{
                    position: 'absolute',
                    left: '50%',
                    bottom: 0,
                    marginBottom: 3,
                    width: 150,
                    transform: 'translateX(-50%)',
                  }}>
                    <Space size={2}>
                      <Text>{`${product.qtyInCart} no Carrinho`}</Text>
                      <Button size='small' onClick={() => {
                        dispatch(removeFromCart(product));
                        dispatch({ type: 'CART_UPDATED', updated: true });
                        dispatch({ type: 'CART_RETURNED', returned: false });
                      }} icon={<DeleteOutlined />} type="link" />
                    </Space>
                  </div>
                )
              }
            </>
          ),
          width: 150,
        };
      },
      width: 150,
      align: 'center',
    },
  ];

  function getCartValues(productId) {
    const filteredCart = cartProducts.filter((cartObj) => productId === cartObj.id);
    const qtyInCart = filteredCart[0] ? filteredCart[0].quantity : 0;
    return qtyInCart;
  }

  const cartQuantities = React.useMemo(() => {
    const quantities = {};
    cartProducts.forEach(product => {
      quantities[product.id] = product.quantity;
    });
    return quantities;
  }, [cartProducts]); // Essa dependência garante que só recalculamos quando o carrinho mudar

  useEffect(() => {
    if (productList.loading) {
      setProductLoading(true);
    } else if (productLoading !== null && progressiveDiscountRules !== null) {
      const updatedList = Array.isArray(productList?.list)
        ? productList.list.map((product) => ({
          ...product,
          qtyInCart: getCartValues(product.id),
        }))
        : [];

      const productsRuleApplied = applyBulkDiscount(progressiveDiscountRules, updatedList);

      setUpdatedProductList(productsRuleApplied);
      setProductLoading(false)
    }
  }, [productList, productLoading, cartProducts, progressiveDiscountRules]);



  function updateCart(product, qtyInPage, qtyInCart) {
    dispatch(addToCart(product, qtyInPage, qtyInCart, qtyInPage));
    dispatch({ type: 'SET_CART', updated: true, returned: false })
    dispatch(clearPageQty(product));
  }

  function handleCancel() {
    setActiveTabKey2(screens.md ? 'description' : 'image');
    setFeaturedProduct(null)
  }



  return (
    <>
      {viewType === 'list' ?
        <div style={{ margin: 4, minHeight: '60vh' }}>
          <Table
            columns={screens.lg ? bigColumns : smallColumns}
            dataSource={(productList.loading || productLoading !== false) ? [] : updatedProductList}
            locale={{
              emptyText: (productList.loading || productLoading !== false) ? <Skeleton active /> :
                <Empty description={
                  <Text>
                    Nenhum produto encontrado
                  </Text>
                } />,
            }}
            sortDirections={['ascend']}
            onRow={(record, rowIndex) => ({
              onClick: (event) => {
                if (screens.md && !event.target.closest('.edit-price-area')) {
                  if (rowIndex !== rowOnFocus) {
                    setRowOnFocus(rowIndex);
                  } else {
                    handleFocus();
                  }
                }
              },
              style: {
                backgroundColor: rowIndex === rowOnFocus ? token.colorBgLayout : 'transparent',
              },
            })}
            pagination={{
              pageSizeOptions: [12, 24, 60],
              position: ['bottomCenter'],
              current: page,
              total: totalPages * perPage,
              pageSize: perPage,
            }}
            expandable={{
              expandIconColumnIndex: 8,
              expandedRowRender: (product) => (product.gtin ? (
                <>{`GTIN: ${product.gtin}`}</>
              ) : (
                <>GTIN não registrado</>
              )),
              expandIcon: ({ expanded, onExpand, record }) => (expanded ? (
                <UpOutlined onClick={(e) => onExpand(record, e)} />
              ) : (
                <DownOutlined onClick={(e) => onExpand(record, e)} />
              )),
            }}
            onChange={(val, filters, sorter) => {
              setRowOnFocus(0);
              dispatch({
                type: 'SET_PAGINATION',
                page: val.current,
                perPage: val.pageSize,
                pageUpdated: false,
                column: sorter.column ? sorter.column.title : null,
                order: sorter.order ? sorter.order : null,
              });
            }}
          />
        </div> :
        <div style={{ margin: 8, minHeight: '60vh' }}>
          {(productList.loading || productLoading !== false) ? <div style={{
            borderRadius: token.borderRadius,
            padding: token.padding,
            backgroundColor: token.colorBgBase,
          }}>
            <Row gutter={[16, 16]}>
              {Array(4).fill(
                <Col xs={24} sm={12} md={8} lg={6}>
                  <Card
                    style={{
                      marginBottom: 16,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {/* Imagem */}
                    <div
                      style={{
                        textAlign: 'center',
                        marginBottom: 16,
                        height: 200,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Skeleton.Image active style={{ width: '100%', height: '100%', maxHeight: 200 }} />
                    </div>
                    <div
                      style={{
                        marginTop: 8,
                        marginBottom: 8,
                        height: 48,
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Skeleton.Node active style={{ width: 150, height: 150 }} />
                    </div>
                  </Card>
                </Col>
              )}
            </Row>
          </div> :
            updatedProductList.length > 0 ?
              <>
                <Row gutter={[8, 8]}>
                  {updatedProductList.map((product) => {
                    const qtyInCart = cartQuantities[product.id] || 0;

                    return (
                      <ProductCard
                        key={product.id}
                        product={product}
                        qtyInCart={qtyInCart}
                        updateFeaturedProduct={updateFeaturedProduct}
                        orderOutOfStock={sellerData.orderOutOfStock}
                      />
                    );
                  })}
                </Row>
                <Pagination
                  align="center"
                  style={{ padding: 10 }}
                  current={page}
                  total={totalPages * perPage}
                  pageSize={perPage}
                  pageSizeOptions={[12, 24, 60]}
                  onChange={(newPage, newPerPage) => {
                    dispatch({
                      type: 'SET_PAGINATION',
                      page: newPage ? newPage : page,
                      perPage: newPerPage ? newPerPage : perPage,
                      pageUpdated: false,
                    });
                  }}
                />
              </> :
              <div style={{
                borderRadius: token.borderRadius,
                padding: token.padding,
                margin: token.margin,
                backgroundColor: token.colorBgBase,
              }}>
                <Empty description={
                  <Text>
                    Nenhum produto encontrado
                  </Text>
                } />
              </div>
          }
        </div>
      }
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{
            visible,
            onVisibleChange: (vis) => {
              setVisible(vis);
              ~vis && setImageUrls([]);
            },
          }}
        >
          {imageUrls.map((x, index) => (
            <Image key={index} src={x} />
          ))}
        </Image.PreviewGroup>
      </div>
      <Modal
        destroyOnClose
        centered
        width="95%"
        footer={
          <Button key="back" onClick={handleCancel}>
            Fechar
          </Button>
        }
        title={featuredProduct?.product_name}
        open={featuredProduct !== null}
        onCancel={handleCancel}
        styles={{
          body: {
            overflowY: "auto",
            overflowX: "hidden",
            padding: 0,
            margin: 0,
          },
        }}
      >
        <Row gutter={[16, 16]}>
          {screens.md && (<Col xs={24} md={12}>

            <Card size='small'>
              <ImageGallery
                showFullscreenButton={false}
                showPlayButton={false}
                items={featuredProduct?.product_image.map((item) => ({
                  original: item.url,
                  thumbnail: item.url,
                }))}
                showThumbnails={true}
                thumbnailPosition={"left"}
                renderItem={(item) => (
                  <Image
                    width="auto"
                    height="65vh"
                    src={item.original}
                    style={{ objectFit: "cover", cursor: "pointer" }}
                    preview={{ mask: false }}
                  />
                )}
                renderThumbInner={(item) => (
                  <div style={{ width: "70px", height: "70px" }}>
                    <img
                      src={item.thumbnail}
                      alt=""
                      style={{ width: "100%", height: "100%", objectFit: "cover" }}
                    />
                  </div>
                )}
              />
            </Card>
          </Col>)}
          <Col xs={24} md={12}>
            <Card
              size='small'
              style={{
                width: "100%",
                height: "100%",
              }}
              tabList={screens.md
                ? [
                  { key: "description", tab: "Descrição" },
                  { key: "details", tab: "Detalhes" },
                ] : [
                  { key: "image", tab: "Imagens" },
                  { key: "description", tab: "Descrição" },
                  { key: "details", tab: "Detalhes" },
                ]}
              activeTabKey={activeTabKey2}
              onTabChange={setActiveTabKey2}
              tabProps={{
                size: "large",
              }}
            >
              {activeTabKey2 === "image" && !screens.md && (
                <ImageGallery
                  showFullscreenButton={false}
                  showPlayButton={false}
                  items={featuredProduct?.product_image.map((item) => ({
                    original: item.url,
                    thumbnail: item.url,
                  }))}
                  showThumbnails={true}
                  thumbnailPosition={"bottom"}
                  renderItem={(item) => (
                    <Image
                      width="auto"
                      height="50vh"
                      src={item.original}
                      style={{ objectFit: "cover", cursor: "pointer" }}
                      preview={{ mask: false }}
                    />
                  )}
                  renderThumbInner={(item) => (
                    <div style={{ width: "70px", height: "70px" }}>
                      <img
                        src={item.thumbnail}
                        alt=""
                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                      />
                    </div>
                  )}
                />
              )}
              {activeTabKey2 === "description" && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: featuredProduct?.description
                      ? /<.*?>.*<\/.*?>/.test(featuredProduct.description)
                        ? featuredProduct.description
                        : featuredProduct.description.replace(/\n/g, "<br />")
                      : "Produto sem descrição",
                  }}
                  style={{
                    height: "45vh",
                    overflowY: "auto",
                  }}
                />
              )}
              {activeTabKey2 === "details" && (
                <Descriptions bordered column={1} size="middle">
                  <Descriptions.Item label="Código">{featuredProduct?.product_sku}</Descriptions.Item>
                  <Descriptions.Item label="Preço">
                    {featuredProduct?.product_price_sale
                      ? `R$ ${featuredProduct.product_price_sale.toFixed(2)} (Promoção)`
                      : `R$ ${featuredProduct?.product_price.toFixed(2)}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Estoque">{featuredProduct?.product_stock}</Descriptions.Item>
                  <Descriptions.Item label="Dimensões">
                    {featuredProduct?.length}cm x {featuredProduct?.width}cm x {featuredProduct?.height}cm
                  </Descriptions.Item>
                  <Descriptions.Item label="Peso">{featuredProduct?.weight} kg</Descriptions.Item>
                  <Descriptions.Item label="Volume">{featuredProduct?.volume} cm³</Descriptions.Item>
                </Descriptions>
              )}
            </Card>
          </Col>
        </Row>
      </Modal>
    </>
  );
});

export default BuyerListProduct;