import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  App, Form, Card, Select, Checkbox, Input, Modal, Table, Button, Skeleton, Empty, Space, notification,
  Typography,
} from 'antd';
import { axiosClient } from '../../apiClient';
import Editor from 'react-simple-wysiwyg';


const { Link } = Typography;
const { Option } = Select;

const SellerListAutomation = () => {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [automations, setAutomations] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [html, setHtml] = useState(`
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <p>Oi {clienteNome}, tudo bem? Percebi que você pode estar precisando repor alguns produtos. Me manda um WhatsApp se precisar de algo, ou, se preferir, já te deixo o link para comprar direto no site:</p><p><br>
  <a style="display: inline-block; background-color: #007bff; color: #fff; text-decoration: none; padding: 10px 20px; border-radius: 5px; font-size: 16px;">Acessar o Catálogo&nbsp;</a>
`);

  const sendEmail = Form.useWatch('send_email', form);
  const sendCoupon = Form.useWatch('send_coupon', form);

  useEffect(() => {
    axiosClient
      .get('/automations/list', { withCredentials: true })
      .then(({ data }) => {
        setAutomations(data.automations);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    axiosClient
      .post('/rules/list', { actions: ['discount', 'freeShipping', 'progressiveDiscount'] }, { withCredentials: true })
      .then(({ data }) => {
        setCampaigns(data.rules);
      });
  }, []);

  const handleSave = (values) => {
    const request = selectedRecordId
      ? axiosClient.post('/automations/update', { automationId: selectedRecordId, ...values }, { withCredentials: true })
      : axiosClient.post('/automations/create', values, { withCredentials: true });

    request
      .then(() => {
        message.success('Operação realizada com sucesso', 4)
        setModalOpen(false);
        form.resetFields();
        setSelectedRecordId(null);
        return axiosClient.get('/automations/list', { withCredentials: true });
      })
      .then(({ data }) => setAutomations(data.automations));
  };

  const handleDelete = () => {
    if (!selectedRecordId) return;
    axiosClient
      .post('/automations/delete', { automationId: selectedRecordId }, { withCredentials: true })
      .then(() => {
        message.success('Automação excluida com sucesso', 4)
        setModalOpen(false);
        setSelectedRecordId(null);
        return axiosClient.get('/automations/list', { withCredentials: true });
      })
      .then(({ data }) => setAutomations(data.automations));
  };

  const columns = [
    {
      title: 'Gatilho',
      ellipsis: true,
      responsive: ['sm'],
      render: (record) => <Link>
        {' '}
        {optionTitles[record.trigger]}
        {' '}
      </Link>,
    },
    {
      title: 'Ações',
      responsive: ['sm'],
      render: (record) => {
        const actions = [];
        if (record.send_whatsapp) actions.push('Whatsapp');
        if (record.send_coupon) actions.push('Cupom');
        if (record.send_email) actions.push('Email');

        return actions.length > 0 ? <Space split=",">{actions.join(', ')}</Space> : 'Não Configurado';
      },
    },
    {
      title: 'Automações',
      render: (record) => {
        const actions = [];
        if (record.send_whatsapp) actions.push('Whatsapp');
        if (record.send_coupon) actions.push('Cupom');
        if (record.send_email) actions.push('Email');
        return (
          <>
            <Link>
              {optionTitles[record.trigger]}
            </Link>
            <br />
            Ações: {actions.length > 0 ? <Space split=",">{actions.join(', ')}</Space> : 'Não Configurado'}<br />
          </>
        )
      },
      responsive: ['xs'],
      ellipsis: true,
    },
  ];

  const optionTitles = {
    readyToBuy: "Status do Cliente Alterou para 'Pronto Para Comprar'",
    atRisk: "Status do Cliente Alterou para 'Em Risco'",
    lost: "Status do Cliente Alterou para 'Perdido'",
    abandonedCart: "Cliente Abandonou um Carrinho",
    updatedCart: "Cliente Atualizou um Carrinho",
    placedOrder: "Cliente Realizou um Pedido"
  };

  return (
    <>
      <PageHeader
        onBack={() => navigate('/admin')}
        title="Automações"
        extra={
          <Button onClick={() => {
            setSelectedRecordId(null);
            form.resetFields();
            setModalOpen(true);
          }} type="primary">
            Criar Automação
          </Button>
        }
      />
      <Table
        style={{ cursor: !loading ? 'pointer' : 'auto' }}
        columns={columns}
        dataSource={automations}
        locale={{ emptyText: loading ? <Skeleton active /> : <Empty /> }}
        onRow={(record) => ({
          onClick: () => {
            form.setFieldsValue({
              ...record,
            });
            setSelectedRecordId(record.id);
            setModalOpen(true);
          },
        })}
      />
      <Modal
        centered
        open={modalOpen}
        title={selectedRecordId ? 'Editar Automação' : 'Cadastrar Automação'}
        okText={selectedRecordId ? 'Salvar' : 'Cadastrar'}
        cancelText="Cancelar"
        onCancel={() => {
          setModalOpen(false);
          form.resetFields();
          setSelectedRecordId(null);
        }}
        onOk={() => form.submit()}
        footer={
          <>
            <Button onClick={() => setModalOpen(false)}>Cancelar</Button>
            {selectedRecordId && (
              <Button danger onClick={handleDelete}>
                Excluir
              </Button>
            )}
            <Button type="primary" onClick={() => form.submit()}>
              {selectedRecordId ? 'Salvar' : 'Cadastrar'}
            </Button>
          </>
        }
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSave}
        >
          <Space style={{ width: '100%' }} size='small' direction='vertical'>

            <Card size='small' title="Gatilho">
              <Form.Item name='trigger'>
                <Select
                  disabled={selectedRecordId ? true : false}
                  placeholder="Gatilho da automação"
                >
                  {Object.keys(optionTitles).map(trigger => (
                    <Option
                      key={trigger}
                      value={trigger}
                      disabled={automations.some(automation => automation.trigger === trigger)}
                    >
                      {optionTitles[trigger]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Card>
            <Card size='small' title="Cupons">
              <Form.Item name="send_coupon" valuePropName="checked">
                <Checkbox >Criar Cupom Para o Cliente</Checkbox>
              </Form.Item>
              {sendCoupon &&
                <Form.Item name='action' label="Selecione a Campanha">
                  <Select
                    placeholder="Selecione a Campanha"
                  >
                    {campaigns.map(campaign => (
                      <Option key={campaign.id} value={campaign.id}>
                        {campaign.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              }
            </Card>
            <Card size='small' title="Contato">
              <Form.Item name="send_whatsapp" valuePropName="checked">
                <Checkbox >Enviar Whatsapp para o Vendedor</Checkbox>
              </Form.Item>
              <Form.Item name="send_email" valuePropName="checked">
                <Checkbox >Enviar E-mail Para o Cliente</Checkbox>
              </Form.Item>

              {sendEmail && <Editor value={html} onChange={(e) => {
                setHtml(e.target.value);
              }} />}
            </Card>

          </Space >
        </Form>
      </Modal >
    </>
  );
};

export default SellerListAutomation;
