import {
  App,
  Alert,
  Button,
  Upload,
  Form,
  Select,
  Row,
  Col,
  Switch,
  Space,
  Progress,
  Typography,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { axiosClient } from '../../apiClient';

const { Text } = Typography;
const { Option } = Select;

export default function SellerListCustomerImportClientsSheet(props) {
  const { message } = App.useApp();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const XLSX = require('xlsx');
  const [importingClients, setImportingClients] = useState(false);
  const [step, setStep] = useState(1);
  const [clientsData, setClientsData] = useState(null);
  const [importClientsPercentage, setImportClientsPercentage] = useState(0);
  const [importFile, setImportFile] = useState(null);
  const [stopImportFile, setStopImportFile] = useState(false);
  const [sellersRegistered, setSellersRegistered] = useState(null);
  const [sellersCorrelation, setSellersCorrelation] = useState(null);
  const [sheetSellers, setSheetSellers] = useState(null);
  const [sheetName, setSheetName] = useState(null);
  const [sheetModel, setSheetModel] = useState('tiny');
  const [sheetType] = useState('xls');

  useEffect(() => {
    axiosClient
      .get('/auth/list/seller-users', { withCredentials: true })
      .then((response) => {
        const sellers = [];
        response.data.forEach((user) => {
          if (user.user_role.includes('seller')) {
            user.nome = `${user.nome} (vendedor)`;
          } else if (user.user_role.includes('manager')) {
            user.nome = `${user.nome} (gestor)`;
          }
          sellers.push(user);
        });
        setSellersRegistered(sellers);
      });
  }, []);

  const downloadModelSheet = () => {
    axiosClient
      .post(
        '/integrations/download-import-sheet',
        {
          erp: sheetModel,
          file_type: 'xls',
          data_type: 'buyers'
        },
        {
          withCredentials: true,
          responseType: 'blob',
        },
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `modelo_${sheetModel}.${sheetType}`);
        document.body.appendChild(link);
        link.click();
      });
  };

  function resetState(reload_list = false) {
    setImportingClients(false);
    setImportClientsPercentage(0);
    props.drawerSetter(false);
    setStep(1);
    if (reload_list === true) {
      dispatch({
        type: 'SET_BUYER_PAGINATION',
        pageUpdated: false,
      });
    }
  }

  // CHECKING SPREADSHEET (STEP 1)
  const checkSheet = (values) => {
    if (importFile !== null) {
      const { file } = values.sheet_file;
      const reader = new FileReader();
      reader.onload = async function (e) {
        const data = e.target.result;
        const workbook = XLSX.read(data, {
          type: 'binary',
          codepage: 65001,
        });
        const clients_json = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
        );
        setClientsData(clients_json);
      };
      reader.readAsBinaryString(file);
    } else {
      message.error('Você deve primeiro selecionar o arquivo da planilha', 4);
      setImportingClients(false);
    }
  };

  useEffect(() => {
    if (clientsData) {
      if (sheetModel.toLowerCase().includes('tiny') || sheetModel.toLowerCase().includes('bling')) {
        const sellers = [];
        clientsData.forEach((client_json) => {
          if ('Vendedor' in client_json && !sellers.includes(client_json.Vendedor)) {
            sellers.push(client_json.Vendedor);
          }
        });
        if (sellers.length > 0) {
          setSheetSellers(sellers);
        } else {
          setSheetSellers([]);
        }
      }
    }
  }, [clientsData]);
  /// /////////////////////////////////////////////

  // CORRELATING SELLER FROM ECOMMERCE TO SELLERS FROM SPREADSHEET (STEP 2)
  useEffect(() => {
    if (sheetSellers && sellersRegistered) {
      if (sheetSellers.length > 0) {
        setSellersCorrelation(
          <>
            <div style={{ marginTop: 30, marginBottom: 10 }}>
              <p>Associe os gestores e vendedores da planilha com os registrados na plataforma:</p>
              <Alert
                message="Caso o gestor ou vendedor ainda não esteja registrado na plataforma, cadastre antes de continuar"
                type="info"
                showIcon
                action={(
                  <Button
                    size="small"
                    onClick={() => { registerSeller(); }}
                  >
                    Cadastrar
                  </Button>
                )}
              />
            </div>
            {sheetSellers.map((seller, i) => (
              <Form.Item
                label={seller}
                name={seller}
              >
                <Select
                  key={i}
                  disabled={importingClients}
                  placeholder="Selecione um vendedor"
                  block
                >
                  {sellersRegistered.map((seller_registered, i) => (
                    <Option
                      value={seller_registered.id}
                      key={i}
                    >
                      {seller_registered.nome}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ))}
          </>,
        );
      } else {
        setSellersCorrelation(null);
        setStep(2);
      }
    }
  }, [sheetSellers]);

  useEffect(() => {
    (sellersCorrelation)
      && setStep(2);
  }, [sellersCorrelation]);

  const layout = {
    layout: 'vertical',
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const normFile = (e) => {
    setSheetName(e.fileList[0].name);
    e.fileList.length > 0 ? setImportFile(e.file) : setImportFile(null);
  };

  // IMPORTING CLIENTS (STEP 3)
  const importClients = (values) => {
    if (importFile !== null) {
      const { generate_pass_cpfcnpj } = values;
      const { apply_to_registed } = values;
      const { send_email } = values;

      setImportingClients(true);

      const reader = new FileReader();
      reader.onload = async function (e) {
        const data = e.target.result;
        const workbook = XLSX.read(data, {
          type: 'binary',
          codepage: 65001,
        });
        const clients_json = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
        );
        const to_upload = clients_json.length;
        let uploaded = 0;

        async function send_client(clients_json) {
          const promises = clients_json.map(async (client_json) => {
            if (sheetModel === 'tiny' || sheetModel === 'bling') {
              if ('Vendedor' in client_json) {
                if (values[client_json.Vendedor]) client_json.Vendedor = values[client_json.Vendedor];
                else delete client_json.Vendedor;
              }
            }
            return await axiosClient
              .post(
                `/integrations/${sheetModel}/clients-import`,
                {
                  client: client_json,
                  send_email,
                  generate_pass_cpfcnpj,
                  apply_to_registed,
                },
                { withCredentials: true },
              )
              .then((response) => {
                if (response.data.status === 'Error') {
                }
              })
              .catch((error) => {
                console.log('ERROR!!!');
              });
          });
          return await axios.all(promises);
        }
        const simultaneous_requests = 2;
        for (let i = 0; i < clients_json.length; i += simultaneous_requests) {
          const start = new Date();
          if (stopImportFile === false) {
            const clients_pack_json = clients_json.slice(
              i,
              i + simultaneous_requests,
            );
            await send_client(clients_pack_json);
            uploaded += clients_pack_json.length;
            setImportClientsPercentage(parseInt((uploaded * 100) / to_upload));
            const end = new Date();
            console.log(`${uploaded}/${to_upload}: ${end - start} msec`);
          }
        }
        uploaded === to_upload
          ? message.success('Clientes importados com sucesso', 4)
          : message.warning(
            `Atenção! ${to_upload - uploaded
            } clientes não foram adicionados. Tente novamente`,
            4,
          );
        resetState(true);
      };
      reader.onerror = function (ex) {
        console.log(ex);
      };
      reader.readAsBinaryString(importFile);
    } else {
      message.error('Você deve primeiro selecionar o arquivo da planilha', 4);
      setImportingClients(false);
    }
  };

  const registerSeller = () => {
    const url = new URL(window.location.href.split('?')[0]);
    window.location.replace(`${url}?listusers`);
  };
  /// ////////////////////////////////////////////////////////////

  return (
    <>
      {step === 1 && (
        <Form
          form={form}
          scrollToFirstError
          {...layout}
          name="nest-messages"
          onFinish={checkSheet}
          initialValues={{
            send_email: false,
            generate_pass_cpfcnpj: true,
            apply_to_registed: false,
            sheet_model: 'tiny',
            sheet_file: null,
          }}
        >
          <Form.Item name={['sheet_model']} label="Modelo da planilha">
            <Select
              block
              defaultValue="tiny"
              onChange={(value) => setSheetModel(value)}
            >
              {/* <Option value="padrao">Padrão</Option> */}
              <Option value="tiny">Tiny ERP</Option>
              <Option value="bling">Bling ERP</Option>
            </Select>
            <span> Formatos aceitos: xls, xlsx, csv</span>
            <Button type="link" onClick={() => downloadModelSheet()}>
              Baixar modelo
            </Button>
          </Form.Item>

          <div style={{ marginTop: 30 }}>
            <Space size={10}>
              <Form.Item
                valuePropName="importSheet"
                name={['sheet_file']}
                noStyle
              >
                <Upload
                  onChange={normFile}
                  progress
                  accept=".xls, .xlsx, .csv"
                  // showUploadList={false}
                  maxCount={1}
                  beforeUpload={() => false}
                >
                  <Button>
                    <UploadOutlined />
                    {' '}
                    Selecionar Planilha
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item
                valuePropName="sheetModel"
                name={['sheet_model']}
                noStyle
              />
            </Space>
          </div>
          <Form.Item>
            <div style={{ marginTop: 30 }}>
              <Row justify="center" gutter={[12, 8]}>
                <Col xs={24} sm={12}>
                  <Button
                    block
                    onClick={() => resetState()}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col xs={24} sm={12}>
                  <Button
                    block
                    loading={importingClients}
                    type="primary"
                    htmlType="submit"
                  >
                    Carregar Planilha
                  </Button>
                </Col>
              </Row>
            </div>
          </Form.Item>
        </Form>
      )}
      {step === 2 && (
        <Form
          form={form}
          scrollToFirstError
          {...layout}
          name="nest-messages"
          onFinish={importClients}
          initialValues={{
            send_email: false,
            generate_pass_cpfcnpj: true,
            apply_to_registed: false,
            sheet_model: 'tiny',
            sheet_file: null,
          }}
        >
          {importingClients === false ? (
            <>
              <div style={{ marginTop: 10 }}>
                <Space size={10}>
                  <h3> Configure a importação dos clientes:</h3>
                </Space>
              </div>

              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Space size={10}>
                  <Text>Nome do arquivo: </Text>
                  <Text keyboard>{sheetName}</Text>
                </Space>
              </div>

              <div style={{ marginTop: 10, marginBottom: 10 }}>
                <Space size={10}>
                  <Text>Quantidade de clientes: </Text>
                  <Text keyboard>{clientsData.length}</Text>
                </Space>
              </div>
              <Space size={10}>
                <Form.Item
                  name="send_email"
                  valuePropName="checked"
                  noStyle
                >
                  <Switch key="sw1" disabled={importingClients} size="small" />
                </Form.Item>
                <span> Enviar email para novos clientes com dados de acesso</span>
              </Space>

              <div style={{ marginTop: 10 }}>
                <Space size={10}>
                  <Form.Item
                    name="generate_pass_cpfcnpj"
                    valuePropName="checked"
                    noStyle
                  >
                    <Switch key="sw2" defaultChecked disabled={importingClients} size="small" />
                  </Form.Item>
                  <span>
                    Gerar senhas de acesso para novos clientes (4 primeiros digitos
                    do CPF/CNPJ)
                  </span>
                </Space>
              </div>

              <div style={{ marginTop: 10 }}>
                <Space size={10}>
                  <Form.Item
                    name="apply_to_registed"
                    valuePropName="checked"
                    noStyle
                  >
                    <Switch key="sw3" defaultChecked disabled={importingClients} size="small" />
                  </Form.Item>
                  <span>Atualizar informações de clientes já cadastrados</span>
                </Space>
              </div>

              {sellersCorrelation}
            </>
          ) : (
            <>
              <div style={{ marginTop: 10 }}>
                <Space size={10}>
                  <h3> Importando clientes... </h3>
                </Space>
              </div>
              <Progress percent={importClientsPercentage} />
            </>
          )}
          <>
            <Form.Item>
              <div style={{ marginTop: 30 }}>
                <Row justify="center" gutter={[12, 8]}>
                  <Col xs={24} sm={12}>
                    <Button
                      block
                      disabled={importingClients}
                      onClick={() => { setStep(1); }}
                    >
                      Voltar
                    </Button>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Button
                      block
                      loading={importingClients}
                      type="primary"
                      htmlType="submit"
                    >
                      Importar Clientes
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form.Item>
          </>
        </Form>
      )}
    </>
  );
}
