import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Button, Input, Space } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

export default function QtyChangerRow(props) {
  const dispatch = useDispatch();
  const pageQty = useSelector((state) => state.pageQty);

  if (props.orderOutOfStock === undefined) {
    return null;
  }

  const stock = props.orderOutOfStock ? 10000 : props.product.product_stock;
  const qtyInPage = pageQty[props.product.id] || 0;

  function updateValue(e) {
    const value = e.target.value.replace(/\D/g, '');
    dispatch({
      type: 'SET_QUANTITY',
      productId: props.product.id,
      quantity: value ? parseInt(value, 10) : 0,
    });
  }

  function detectMob() {
    return window.innerWidth <= 800;
  }

  useEffect(() => {
    if (props.rowOnFocus === props.myIndex) {
      if (stock <= 0 || stock - qtyInPage <= 0) {
        props.setRowOnFocus(props.rowOnFocus + 1);
      } else if (!detectMob() && !props.searchOnFocus) {
        props.handleFocus();
      }
    }
  }, [props.rowOnFocus, stock, qtyInPage, props]);

  return (
    <Space.Compact>
      <Button
        onClick={() =>
          dispatch({ type: 'DECREASE_QUANTITY', productId: props.product.id })
        }
        disabled={stock <= 0 || qtyInPage <= 0}
        icon={<MinusOutlined />}
      />
      <Input
        ref={props.rowOnFocus === props.myIndex ? props.inputRef : null}
        onFocus={(e) => e.target.focus()}
        onPressEnter={() => {
          props.setRowOnFocus(props.rowOnFocus + 1);
          if (qtyInPage) {
            props.updateCart(props.product, qtyInPage, props.qtyInCart);
          }
        }}
        pattern="\d*"
        onChange={updateValue}
        style={{ width: 50, textAlign: 'center', borderRadius: 0 }}
        value={qtyInPage}
        disabled={stock <= 0 || stock - props.qtyInCart <= 0}
      />
      <Button
        onClick={() =>
          dispatch({ type: 'INCREASE_QUANTITY', productId: props.product.id })
        }
        disabled={stock - props.qtyInCart <= 0 || stock - props.qtyInCart - qtyInPage <= 0}
        icon={<PlusOutlined />}
      />
    </Space.Compact>
  );
}
