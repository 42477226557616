import React, { useEffect, useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import {
  App,
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  Divider,
  Tooltip,
  Dropdown,
  Menu,
  Space,
  Switch,
  Skeleton,
  Alert,
  Modal,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  DownOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  KeyOutlined,
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  mascaraPhone,
  mascaraCnpj,
  mascaraCpf,
  validaCpfCnpj,
} from '../../functions/validation';
import { axiosClient } from '../../apiClient';

const { Option, OptGroup } = Select;

export default function SellerCreateCustomer() {
  const { message } = App.useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  form.setFieldsValue({
    send_email: true,
  });
  const [tipoPessoa, setTipoPessoa] = useState('pj');
  const [changePass, setChangePass] = useState(true);
  const [availableTags, setAvailableTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [priceLists, setPriceLists] = useState([]);
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [searching, setSearching] = useState(false);
  const [selectedSellerUser, setSelectedSellerUser] = useState({
    value: null,
  });
  const [oldSellerUser, setOldSellerUser] = useState({
    value: null,
  });
  const [showSellerModal, setShowSellerModal] = useState(false);
  const [sellerUsers, setSellerUsers] = useState(null);
  const [buyerRequest, setBuyerRequest] = useState(null);
  const logged = useSelector((state) => state.authentication.logged);

  const { buyerId } = useParams();
  const { requestId } = useParams();
  const buyerRequestData = location.state;

  useEffect(() => {
    if (buyerRequestData) {
      setBuyerRequest(buyerRequestData);
    } else if (requestId && !buyerRequestData) {
      axiosClient
        .post(
          '/auth/get/buyer/request',
          { requestId: requestId },
          { withCredentials: true },
        ).then((response) => { setBuyerRequest(response.data); });
    }
  }, []);

  const states = [
    { nome: 'Acre', sigla: 'AC' },
    { nome: 'Alagoas', sigla: 'AL' },
    { nome: 'Amapá', sigla: 'AP' },
    { nome: 'Amazonas', sigla: 'AM' },
    { nome: 'Bahia', sigla: 'BA' },
    { nome: 'Ceará', sigla: 'CE' },
    { nome: 'Distrito Federal', sigla: 'DF' },
    { nome: 'Espírito Santo', sigla: 'ES' },
    { nome: 'Goiás', sigla: 'GO' },
    { nome: 'Maranhão', sigla: 'MA' },
    { nome: 'Mato Grosso', sigla: 'MT' },
    { nome: 'Mato Grosso do Sul', sigla: 'MS' },
    { nome: 'Minas Gerais', sigla: 'MG' },
    { nome: 'Pará', sigla: 'PA' },
    { nome: 'Paraíba', sigla: 'PB' },
    { nome: 'Paraná', sigla: 'PR' },
    { nome: 'Pernambuco', sigla: 'PE' },
    { nome: 'Piauí', sigla: 'PI' },
    { nome: 'Rio de Janeiro', sigla: 'RJ' },
    { nome: 'Rio Grande do Norte', sigla: 'RN' },
    { nome: 'Rio Grande do Sul', sigla: 'RS' },
    { nome: 'Rondônia', sigla: 'RO' },
    { nome: 'Roraima', sigla: 'RR' },
    { nome: 'Santa Catarina', sigla: 'SC' },
    { nome: 'São Paulo', sigla: 'SP' },
    { nome: 'Sergipe', sigla: 'SE' },
    { nome: 'Tocantins', sigla: 'TO' },
  ];

  useEffect(() => {
    axiosClient
      .get('/config/price-list/list', { withCredentials: true })
      .then(({ data }) => {
        setPriceLists(data.price_lists);
      });
  }, []);

  useEffect(() => {
    axiosClient
      .get('/customer/tag/list', { withCredentials: true })
      .then((out) => {
        setAvailableTags(out.data.tags);
      });
  }, []);

  useEffect(() => {
    (logged === 'seller' || logged === 'sellerUser')
      && axiosClient
        .get('/auth/list/seller-users', { withCredentials: true })
        .then((response) => {
          setSellerUsers(
            response.data.map((value) => ({
              value: value.id,
              label: value.nome,
            })),
          );
        });
  }, []);

  useEffect(() => {
    if (buyerId) {
      setLoading(true);
      setChangePass(false);
      axiosClient
        .post(
          '/auth/get/buyer',
          { customerId: buyerId },
          { withCredentials: true },
        )
        .then((response) => {
          delete response.data.tags;
          const cleanData = Object.fromEntries(
            Object.entries(response.data).filter(([_, v]) => v != null),
          );
          form.setFieldsValue(cleanData);
          cleanData.tipo_pessoa === 'pf'
            && form.setFieldsValue({ cpf: mascaraCpf(cleanData.cpf) });
          cleanData.tipo_pessoa === 'pj'
            && form.setFieldsValue({ cnpj: mascaraCnpj(cleanData.cnpj) });
          setSelectedTags(cleanData.tags_ids);
          setTipoPessoa(cleanData.tipo_pessoa);
          setLoading(false);
          setOldSellerUser({
            value: response.data.selleruser ? cleanData.selleruser.id : false,
            label: response.data.selleruser ? cleanData.selleruser.nome : false,
          });
          setSelectedSellerUser({
            value: response.data.selleruser ? cleanData.selleruser.id : false,
            label: response.data.selleruser ? cleanData.selleruser.nome : false,
          });
        });
    } else if (buyerRequest) {
      setLoading(true);
      const cleanData = Object.fromEntries(
        Object.entries(buyerRequest).filter(([_, v]) => v != null),
      );
      form.setFieldsValue(cleanData);
      cleanData.tipo_pessoa === 'pf'
        && form.setFieldsValue({ cpf: mascaraCpf(cleanData.cpf) });
      cleanData.tipo_pessoa === 'pj'
        && form.setFieldsValue({ cnpj: mascaraCnpj(cleanData.cnpj) });
      // setSelectedTags(cleanData.tags_ids);
      setTipoPessoa(cleanData.tipo_pessoa);
      if (cleanData.selleruser) {
        setOldSellerUser({
          value: cleanData.selleruser.id,
          label: cleanData.selleruser.nome,
        });
        setSelectedSellerUser({
          value: cleanData.selleruser.id,
          label: cleanData.selleruser.nome,
        });
      }
      setLoading(false);
    }
  }, [buyerRequest]);

  useEffect(() => { form.setFieldsValue({ tags: selectedTags }); }, [availableTags, selectedTags]);

  function getAddress() {
    setSearching(true);
    axiosClient
      .post(
        '/shipping/address-from-cep',
        { func: 'consultarCep', data: form.getFieldValue('cep') },
        { withCredentials: true },
      )
      .then((response) => {
        const data = response.data.response;
        data.municipio = data.localidade;
        data.endereco = data.logradouro;
        data.cep = data.cep.replace(/\D/g, '');
        delete data.complemento;
        form.setFieldsValue(data);
        dispatch({ type: 'ACTIVE_CEP', value: data.cep, uf: data.uf });
        setSearching(false);
      })
      .catch((error) => {
        const data = {};
        data.municipio = '';
        data.endereco = '';
        data.cep = '';
        delete data.complemento;
        form.setFieldsValue(data);
        dispatch({ type: 'ACTIVE_CEP', value: '', uf: '' });
        message.warning('CEP Inválido');
        setSearching(false);
      });
  }

  function deleteCustomer() {
    setDeleting(true);
    axiosClient
      .post(
        '/auth/delete/buyer',
        { customerId: buyerId },
        { withCredentials: true },
      )
      .then((response) => {
        response.data.status === 'Success'
          ? message.success('Cliente deletado com sucesso', 4)
          : message.error('Erro ao deletar cliente', 4);
        navigate('/admin/clientes/lista');
        setDeleting(false);
      });
  }

  const layout = {
    layout: 'vertical',
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const validarCPF = (e) => {
    const cpf = mascaraCpf(e.target.value);
    if (validaCpfCnpj(cpf) == false) {
      form.setFields([
        {
          name: 'cpf',
          errors: ['CPF Inválido'],
        },
      ]);
    }
  };

  const validarCNPJ = (e) => {
    const cnpj = mascaraCnpj(e.target.value);
    if (validaCpfCnpj(cnpj) === false) {
      form.setFields([
        {
          name: 'cnpj',
          errors: ['CNPJ Inválido'],
        },
      ]);
    } else {
      axiosClient
        .post('/consult-cnpj',
          { cnpj },
          { withCredentials: true })
        .then(({ data }) => {
          form.setFieldsValue({
            nome_fantasia: data.fantasia,
            razao_social: data.nome,
            cnpj: data.cnpj,
            email: data.email,
            phone: data.telefone,
            cep: data.cep.replace(/\D/g, ''),
            municipio: data.municipio,
            uf: data.uf,
            endereco: data.logradouro,
            bairro: data.bairro,
            numero: data.numero,
            complemento: data.complemento,
          });
        })
    }
  }

  const onFinish = (values) => {
    setSending(true);
    if (buyerId) {
      var apiUrl = '/auth/update/buyer';
      var disp = '/admin/clientes/lista';
      var msg = 'Dados salvos com sucesso';
    } else if (buyerRequest) {
      var apiUrl = '/auth/register/buyer';
      var disp = '/admin/clientes/solicitacoes';
      var msg = 'Solicitação aceita. Cliente adicionado com sucesso';
    } else {
      var apiUrl = '/auth/register/buyer';
      var disp = '/admin/clientes/lista';
      var msg = 'Cliente adicionado com sucesso';
    }
    const data = buyerId
      ? {
        ...values,
        customerId: buyerId,
        selectedSellerUser: selectedSellerUser.value,
      }
      : {
        ...values,
        selectedSellerUser: selectedSellerUser.value,
      };
    axiosClient
      .post(apiUrl, data, { withCredentials: true })
      .then((response) => {
        if (response.data.status === 'Success') {
          message.success(msg, 4);
          if (buyerRequest) {
            buyerRequest.send_email = true;
            axiosClient
              .post(
                '/auth/buyer/request/access/delete',
                buyerRequest,
                { withCredentials: true },
              )
              .then(() => navigate(disp));
          }
        } else {
          response.data.msg === 'User already exists'
            ? message.error('Usuário já cadastrado', 4)
            : message.error('Erro ao salvar dados', 4);
        }
        setSending(false);
        buyerId && navigate(disp);
      });
  };

  const menu = (
    <Menu onClick={() => deleteCustomer()}>
      <Menu.Item key="1">Excluir Cliente</Menu.Item>
    </Menu>
  );

  return (
    <>
      <PageHeader
        title={
          buyerId ? 'Editar Cliente' : (requestId ? 'Solicitação de Acesso' : 'Cadastrar Cliente')
        }
        onBack={() => navigate('/admin/clientes/lista')}
        extra={
          buyerId && (
            <Dropdown overlay={menu} trigger='click'>
              <Button icon={<DownOutlined />} loading={deleting} type="primary">Ações</Button>
            </Dropdown>
          )
        }
      />
      {loading ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          scrollToFirstError
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
          initialValues={{
            tipo_pessoa: 'pj',
          }}
        >
          <Divider orientation="left">Dados gerais</Divider>
          <Form.Item
            name="tipo_pessoa"
            label="Tipo de Pessoa"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Selecione o tipo de pessoa"
              onChange={(value) => setTipoPessoa(value)}
              allowClear
            >
              <Option value="pj">Pessoa Jurídica</Option>
              <Option value="pf">Pessoa Física</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name={['nome_fantasia']}
            label="Nome Fantasia"
            rules={[
              {
                type: 'string',
                required: tipoPessoa === 'pj' ? true : false,
                max: 100,
              },
            ]}
          >
            <Input />
          </Form.Item>

          {tipoPessoa === 'pf' && (
            <>
              <Form.Item
                name={['nome']}
                label="Nome do Responsável"
                rules={[
                  {
                    type: 'string',
                    required: true,
                    max: 100,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['cpf']}
                    label="CPF"
                    rules={[
                      {
                        type: 'string',
                        max: 100,
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      onBlur={validarCPF}
                      maxLength={14}
                      onChange={(e) => {
                        form.setFieldsValue({
                          cpf: mascaraCpf(e.target.value),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['rg']}
                    label="RG"
                    rules={[
                      {
                        type: 'string',
                        max: 100,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {tipoPessoa === 'pj' && (
            <>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['cnpj']}
                    label="CNPJ"
                    rules={[
                      {
                        required: true,
                        type: 'string',
                        max: 100,
                      },
                    ]}
                  >
                    <Input
                      onBlur={validarCNPJ}
                      maxLength={18}
                      onChange={(e) => {
                        form.setFieldsValue({
                          cnpj: mascaraCnpj(e.target.value),
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['razao_social']}
                    label="Razão Social"
                    rules={[
                      {
                        type: 'string',
                        required: true,
                        max: 100,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['ie']}
                    label="Inscrição Estadual"
                    rules={[
                      {
                        type: 'string',
                        max: 100,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name={['email']}
                label="Email"
                rules={[
                  {
                    type: 'email',
                    // required: true,
                    max: 100,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={['phone']}
                label="Celular ou telefone (com DDD)"
                rules={[
                  {
                    type: 'string',
                    // required: true,
                    max: 100,
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      phone: mascaraPhone(e),
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">Tags</Divider>
          <Form.Item
            name='tags'
          >
            <Select placeholder="Selecione as tags" mode="multiple" allowClear>
              {Object.values(availableTags).map((group) => (
                <OptGroup key={group.id} label={group.title}>
                  {group.tags.map((tag) => (
                    <Option key={tag.id} value={tag.id}>
                      {tag.title}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </Form.Item>
          <Divider orientation="left">Lista de Preço</Divider>
          <Form.Item name='price_list_id'>
            <Select
              placeholder="Selecione a lista padrão"
              allowClear
            >
              {priceLists.map((priceList, i) => (
                <Option
                  value={priceList.id}
                >
                  {`${priceList.name} (${priceList.markup_type === 'decrescimo' ? '-' : '+'}${priceList.percentage}%)`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Divider orientation="left">Endereço</Divider>
          {searching == false ? (
            <>
              <Row gutter={16}>
                <Col xs={24} sm={6}>
                  <Form.Item
                    name={['cep']}
                    label="CEP"
                    rules={[
                      {
                        // required: true,
                        max: 8,
                      },
                      {
                        type: 'string',
                      },
                    ]}
                    onChange={(e) => {
                      const cep_value = e.target.value
                        .replace(/\D/g, '')
                        .substring(0, 8);
                      form.setFieldsValue({
                        cep: cep_value,
                      });
                      if (cep_value.length === 8) {
                        getAddress();
                      }
                    }}
                    onBlur={(e) => {
                      const cep_value = e.target.value
                        .replace(/\D/g, '')
                        .substring(0, 8);
                      form.setFieldsValue({
                        cep: cep_value,
                      });
                      getAddress();
                    }}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['municipio']}
                    label="Município"
                    rules={[
                      {
                        type: 'string',
                        max: 100,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6}>
                  <Form.Item
                    name={['uf']}
                    label="UF"
                    rules={[
                      {
                        type: 'string',
                        max: 100,
                      },
                    ]}
                  >
                    <Select disabled>
                      {states.map((state) => (
                        <Option value={state.sigla}>
                          {state.sigla}
                          {' '}
                          -
                          {state.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name={['endereco']}
                    label="Endereço"
                    rules={[
                      {
                        type: 'string',
                        max: 100,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={8}>
                  <Form.Item
                    name={['bairro']}
                    label="Bairro"
                    rules={[
                      {
                        type: 'string',
                        max: 100,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={6}>
                  <Form.Item
                    name={['numero']}
                    label="Número"
                    rules={[
                      {
                        type: 'string',
                        max: 100,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={10}>
                  <Form.Item
                    name={['complemento']}
                    label="Complemento"
                    rules={[
                      {
                        type: 'string',
                        max: 100,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : (
            <Skeleton />
          )}
          <Divider orientation="left">Vendedor</Divider>
          <Space direction="vertical" style={{ width: '100%' }}>
            {oldSellerUser.value
              && selectedSellerUser.value
              && oldSellerUser.value != selectedSellerUser.value && (
                <Alert
                  message={(
                    <>
                      O vendedor padrão deste cliente será modificado de
                      {' '}
                      <b>{oldSellerUser.label}</b>
                      {' '}
                      para
                      {' '}
                      <b>{selectedSellerUser.label}</b>
                    </>
                  )}
                  type="warning"
                  showIcon
                  closable
                />
              )}
            {selectedSellerUser.value == null && (
              <Alert message="Selecione um vendedor." type="error" showIcon />
            )}
            <Select
              value={selectedSellerUser}
              style={{ width: '100%' }}
              onChange={(value, data) => {
                setSelectedSellerUser(data);
                oldSellerUser.value
                  && oldSellerUser.value != value
                  && setShowSellerModal(true);
              }}
              options={sellerUsers}
            />
          </Space>
          <Divider orientation="left">Senha</Divider>
          <Button
            type="link"
            style={{ display: changePass ? 'none' : 'block' }}
            onClick={() => setChangePass(true)}
          >
            Mudar senha
          </Button>
          {changePass && (
            <>
              <Space align="baseline">
                <Form.Item
                  name={['password']}
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input.Password
                    style={{ width: '200px' }}
                    placeholder="Senha"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
                <Form.Item>
                  <Tooltip title="Gerar senha aleatória">
                    <Button
                      type="primary"
                      onClick={() => {
                        form.setFieldsValue({
                          password: Math.floor(1000 + Math.random() * 9000).toString(), // Math.random().toString(36).slice(-6),
                        });
                      }}
                    >
                      <KeyOutlined />
                    </Button>
                  </Tooltip>
                </Form.Item>
              </Space>
              <Form.Item name={['send_email']}>
                Enviar senha por email?
                {' '}
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked
                  onChange={(checked) => {
                    form.setFieldsValue({
                      send_email: checked,
                    });
                  }}
                />
              </Form.Item>
            </>
          )}
          <>
            <Form.Item>
              <div style={{ marginTop: 30 }}>
                {buyerRequest ? (
                  <Row justify="center" gutter={[30, 12]}>
                    <Col xs={18} sm={8}>
                      <Button
                        block
                        onClick={() => {
                          navigate('/admin/clientes/solicitacoes');
                        }}
                      >
                        Voltar
                      </Button>
                    </Col>
                    <Col xs={18} sm={8}>
                      <Button
                        block
                        danger
                        type="primary"
                        onClick={() => {
                          axiosClient
                            .post(
                              '/auth/buyer/request/access/delete',
                              buyerRequest,
                              { withCredentials: true },
                            )
                            .then((response) => {
                              response.data.status == 'Success'
                                && message.success(
                                  'Solicitação de acesso deletada com sucesso.',
                                  4,
                                );
                              navigate('/admin/clientes/solicitacoes');
                            });
                        }}
                      >
                        Deletar solicitação
                      </Button>
                    </Col>
                    <Col xs={18} sm={8}>
                      <Button
                        block
                        type="primary"
                        htmlType="submit"
                        loading={sending}
                      >
                        Liberar acesso
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <Row justify="center" gutter={[30, 12]}>
                    <Col xs={18} sm={6}>
                      <Button
                        block
                        onClick={() => {
                          requestId ? navigate('/admin/clientes/solicitacoes') :
                            navigate('/admin/clientes/lista');
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>
                    <Col xs={18} sm={6}>
                      <Button
                        block
                        type="primary"
                        htmlType="submit"
                        loading={sending}
                      >
                        {buyerId ? 'Salvar' : 'Cadastrar'}
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </Form.Item>
          </>
        </Form>
      )}
      {showSellerModal && (
        <Modal
          title="Selecionar vendedor"
          open={showSellerModal}
          onOk={() => {
            setShowSellerModal(false);
          }}
          onCancel={() => {
            setShowSellerModal(false);
            setSelectedSellerUser({
              label: oldSellerUser.label,
              value: oldSellerUser.value,
            });
          }}
          okText="Trocar Vendedor"
        >
          <p>
            <b>{oldSellerUser.label}</b>
            {' '}
            é o atual vendedor associado a este
            cliente, deseja trocar para
            <b>{selectedSellerUser.label}</b>
            ?
          </p>
        </Modal>
      )}
    </>
  );
}
