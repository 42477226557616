import { combineReducers } from 'redux';
import { authentication } from './authentication';
import { cart } from './cart';
import { pageQty } from './page_quantity';
import { pagination } from './pagination';
import { buyerPagination } from './buyerPagination';
import { searchState } from './search';
import { buyerSearchState } from './buyerSearch';
import { productList } from './product_list';
import { buyerList } from './buyer_list';
import { order } from './order';
import { activeCEP } from './shipping';
import { sellerData } from './sellerData';
import { accessRequests } from './access_requests';
import { message } from './message';
import { buyerView } from './buyerView';
import { sync } from './sync';
import { indexedDB } from './indexedDB';
import { orderRebuy } from './orderRebuy';
import { buildHash } from './buildHash';
import { bills } from './bills';
import { rules } from './rules';
import { themeConfig } from './themeConfig'


const rootReducer = combineReducers({
  authentication,
  cart,
  pageQty,
  pagination,
  buyerPagination,
  searchState,
  buyerSearchState,
  productList,
  buyerList,
  order,
  activeCEP,
  sellerData,
  accessRequests,
  message,
  buyerView,
  sync,
  indexedDB,
  orderRebuy,
  buildHash,
  bills,
  rules,
  themeConfig
});

export default rootReducer;
