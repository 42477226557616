import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs, Form } from 'antd';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import SellerIntegrations from '../SellerIntegrations';
import SellerSubscription from '../SellerSubscription';
import SellerProfile from '../SellerProfile';
import SellerListUser from '../SellerListUser';
import SellerCreateUser from '../SellerCreateUser';

const { TabPane } = Tabs;

export default function SellerConfiguration() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tab, setTab] = useState('1');
  const [userPage, setUserPage] = useState('listUser');
  const [userType, setUserType] = useState('representante_simples');
  const [userForm] = Form.useForm();

  const { tabName } = useParams();

  useEffect(() => {
    setTab(tabName)
  }, [tabName]);



  return (
    <>
      <Tabs
        onChange={(tabValue) => {
          navigate(`/admin/configuracoes/${tabValue}`);
        }}
        activeKey={tab}
        size="large"
        centered
      >
        <TabPane tab="Dados Gerais" key="geral">
          <SellerProfile />
        </TabPane>
        <TabPane tab="Representantes" key="representantes">
          <Routes>
            <Route path="/" element={<SellerListUser />} />
            <Route path="cadastro/:sellerUserId?" element={<SellerCreateUser form={userForm} />} />
          </Routes>
        </TabPane>
        <TabPane tab="Assinatura" key="assinatura">
          <SellerSubscription />
        </TabPane>
        <TabPane tab="Integrações" key="integracoes">
          <SellerIntegrations />
        </TabPane>
      </Tabs>
    </>
  );
}
