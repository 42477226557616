import {
  App,
  Skeleton,
  Card,
  Drawer,
  Button
} from 'antd';
import React, { useEffect, useState } from 'react';
import { axiosClient } from '../../apiClient';

const { Meta } = Card;

export default function IntegrationsMercadoPago() {
  const { message } = App.useApp();
  const [loading, setLoading] = useState(true);
  const [waitingConfMP, setWaitingConfMP] = useState(false);
  const [mercadopagoUrl, setMercadopagoUrl] = useState('#');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [integrationStatus, setIntegrationStatus] = useState(false);

  const refreshToken = () => {
    setLoading(true);
    axiosClient
      .post(
        '/integrations/mercadopago/refresh-token',
        { sellerUrl: window.location.origin },
        { withCredentials: true },
      )
      .then((response) => {
        setLoading(false);
        response.data.status === 'Success'
          ? setIntegrationStatus(true)
          : setIntegrationStatus(false);
        response.data.status === 'Error'
          && setMercadopagoUrl(response.data.mercadopago_url);
      });
  };

  const revokeIntegration = () => {
    setLoading(true);
    axiosClient
      .get('/integrations/mercadopago/revoke', { withCredentials: true })
      .then((response) => {
        refreshToken();
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('code') && params.get('integration') == 'mercadopago') {
      setDrawerVisible(true);
      setWaitingConfMP(true);
      const code = params.get('code');
      window.history.replaceState(null, "", window.location.pathname);
      axiosClient
        .post(
          '/integrations/mercadopago/access-token',
          { code },
          { withCredentials: true },
        )
        .then((response) => {
          setWaitingConfMP(false);
          if (response.data.status === 'Success') {
            refreshToken();
          } else {
            setDrawerVisible(false);
            message.error('Erro realizar integração', 4);
          }
        });
    } else {
      refreshToken();
    }
  }, []);

  const showDrawer = () => {
    setDrawerVisible(true);
  };


  return (
    <>
      <Card
        style={{ width: 300 }}
        cover={(
          <img
            alt="mercadopago"
            style={{
              width: '82%',
              margin: 'auto',
              marginTop: '27px',
            }}
            src="https://storage.googleapis.com/nupedido-public-bucket/static/images/integrations/mercadopago.png"
          />
        )}
        actions={[
          <Button
            onClick={() => {
              showDrawer();
            }}
          >
            Gerenciar
          </Button>,
          // "Em breve"
        ]}
      >
        <Meta
          title="Mercado Pago"
          description="Disponibilizar pagamentos digitais para seus clientes através do Mercado Pago."
        />
      </Card>
      <Drawer
        title="Gerenciamento de integração - Mercado Pago"
        placement="bottom"
        height={window.innerWidth > 575 ? '360px' : '480px'}
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            {!integrationStatus ? (
              <>
                Status: Desativado
                <br />
                <Button
                  href={mercadopagoUrl}
                  type="primary"
                  loading={waitingConfMP}
                >
                  Vincular Conta
                </Button>
              </>
            ) : (
              <>
                Status: Ativo
                <br />
                <Button
                  onClick={() => revokeIntegration()}
                  type="primary"
                  loading={waitingConfMP}
                >
                  Remover Integração
                </Button>
              </>
            )}
          </>
        )}
      </Drawer>
    </>
  );
}
